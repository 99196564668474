import React from 'react';
import { connectStats } from 'react-instantsearch-dom';
import { formatNumber } from '../utils';

interface SaveFiltersMobileInterface {
  nbHits: number,
  onClick: any
}

const SaveFiltersMobile = ({ nbHits, onClick }: SaveFiltersMobileInterface) => (
  <button className="button button-primary" onClick={onClick}>
    See {formatNumber(nbHits)} results
  </button>
);

export default connectStats(SaveFiltersMobile);
