import React, { Component } from 'react';
import qs from 'qs';

const updateAfter = 700;

type RouteState = {
  query?: string;
  page?: string;
  bindings?: string[];
  stores?: string[];
  cities?: string[];
  sortBy?: string;
  hitsPerPage?: string;
};

const routeStateDefaultValues: RouteState = {
  query: '',
  page: '1',
  bindings: undefined,
  stores: undefined,
  cities: undefined,
  sortBy: 'instant_search',
  hitsPerPage: '20',
};

const searchStateToURL = (searchState: any) => {
  const routeState = {
    query: searchState.query,
    page: String(searchState.page),
    bindings: searchState.refinementList && searchState.refinementList.binding,
    stores: searchState.refinementList && searchState.refinementList.filter_store_name,
    cities: searchState.refinementList && searchState.refinementList.filter_city,
    sortBy: searchState.sortBy,
    hitsPerPage:
      (searchState.hitsPerPage && String(searchState.hitsPerPage)) || undefined,
  };

  const location = window.location
  const { protocol, hostname, port = '', pathname, hash } = location;
  const portWithPrefix = port === '' ? '' : `:${port}`;
  const urlParts = location.href.match(/^(.*?)\/search/);
  const baseUrl =
    (urlParts && urlParts[0]) ||
    `${protocol}//${hostname}${portWithPrefix}${pathname}search`;

  const queryParameters: Partial<RouteState> = {};

  if (routeState.query && routeState.query !== routeStateDefaultValues.query) {
    queryParameters.query = encodeURIComponent(routeState.query);
  }
  if (routeState.page && routeState.page !== routeStateDefaultValues.page) {
    queryParameters.page = routeState.page;
  }
  if (
    routeState.bindings &&
    routeState.bindings !== routeStateDefaultValues.bindings
  ) {
    queryParameters.bindings = routeState.bindings.map(encodeURIComponent);
  }
  if (
    routeState.stores &&
    routeState.stores !== routeStateDefaultValues.stores
  ) {
    queryParameters.stores = routeState.stores.map(encodeURIComponent);
  }
  if (
    routeState.cities &&
    routeState.cities !== routeStateDefaultValues.cities
  ) {
    queryParameters.cities = routeState.cities.map(encodeURIComponent);
  }
  if (
    routeState.sortBy &&
    routeState.sortBy !== routeStateDefaultValues.sortBy
  ) {
    queryParameters.sortBy = routeState.sortBy;
  }
  if (
    routeState.hitsPerPage &&
    routeState.hitsPerPage !== routeStateDefaultValues.hitsPerPage
  ) {
    queryParameters.hitsPerPage = routeState.hitsPerPage;
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `${baseUrl}/${queryString}${hash}`;
};

const urlToSearchState = (location: Location) => {
  const queryParameters = qs.parse(location.search.slice(1));
  const {
    query = '',
    page = 1,
    bindings = [],
    stores = [],
    cities = [],
    hitsPerPage,
    sortBy,
  } = queryParameters;

  // `qs` does not return an array when there's a single value.
  const allBindings = Array.isArray(bindings) ? bindings : [bindings].filter(Boolean);
  const allStores = Array.isArray(stores) ? stores : [stores].filter(Boolean);
  const allCities = Array.isArray(cities) ? cities : [cities].filter(Boolean);

  const searchState: any = { range: [] };


  if (query) {
    searchState.query = decodeURIComponent(query as string);
  }
  if (page) {
    searchState.page = page;
  }
  if (allBindings.length) {
    searchState.refinementList = {
      binding: allBindings.map(binding => decodeURIComponent(binding as string)),
      filter_store_name: allStores.map(storeName => decodeURIComponent(storeName as string)),
      filter_city: allCities.map(city => decodeURIComponent(city as string)),
    };
  }
  if (sortBy) {
    searchState.sortBy = sortBy;
  }

  if (hitsPerPage) {
    searchState.hitsPerPage = hitsPerPage;
  }

  return searchState;
};

// @ts-ignore
const withSearchHistoryRouting = (App) =>
  class WithURLSync extends Component {
    state = {
      searchState: urlToSearchState(window.location),
    };

    componentDidMount() {
      window.addEventListener('popstate', this.onPopState);
    }

    componentWillUnmount() {
      // @ts-ignore
      clearTimeout(this.debouncedSetState);
      window.removeEventListener('popstate', this.onPopState);
    }

    // @ts-ignore
    onPopState = ({ state }) =>
      this.setState({
        searchState: state || {},
      });

    // @ts-ignore
    onSearchStateChange = (searchState) => {
      // @ts-ignore
      clearTimeout(this.debouncedSetState);

      // @ts-ignore
      this.debouncedSetState = setTimeout(() => {
        window.history.pushState(
          searchState,
          // @ts-ignore
          null,
          searchStateToURL(searchState)
        );
      }, updateAfter);

      this.setState({ searchState });
    };

    render() {
      const { searchState } = this.state;

      return (
        <App
          {...this.props}
          searchState={searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={searchStateToURL}
        />
      );
    }
  };

export default withSearchHistoryRouting;
